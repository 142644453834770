.loan-terms {
    height: 100%;
    position: relative;

    .loan-terms__back-link {
        position: absolute;
        top: 14px;
        z-index: 1;
        left: 10px;
        color: $outher-space;
        transition: $dur $ease;
        display: inline-block;
        font-size: 10px;
        left: 7%;

        svg {
            transform: rotate(180deg) translateY(-2px);
            fill: $outher-space;
            width: 10px;
            margin-top: 2px;
            margin-right: 10px;
        }

        @include hover {
            color: $theme-blue;
            transition: $dur $ease;

            svg {
                fill: $theme-blue;
                transition: $dur $ease;
            }
        }

        @include mq(1400px) {
            left: 3%;
        }
    }

    .ant-tabs-content-holder {
        background-color: $white;
    }

    .loan-terms__desktop {
        height: 100%;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $theme-blue;
        background-color: $green-medium;
    }

    .ant-tabs-nav-wrap {
        pointer-events: none;

        &::after {
            display: none;
        }
    }

    .form-element {
        margin-bottom: 22px;

        label {
            font-weight: 500;
        }
    }
}

.loan-terms__tab-content-left-text {
    @include mq(640px) {
        margin-bottom: 0;
    }
}

//desktop
.loan-terms__desktop-wrapper {
    display: flex;
    height: 100%;

    .ant-tabs-top {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .ant-tabs-nav-list {
        flex-direction: column;
        width: 100%;
        padding-right: 16px;
    }

    .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
        font-weight: 500;
    }

    .ant-tabs-tab {
        padding: 0;

        div {
            padding: 12px 23px 12px 20px;
            width: 100%;
            margin-bottom: 3px;
        }

        @include hover {
            color: $theme-blue;
        }
    }

    .ant-tabs-nav {
        margin-bottom: 0;
        width: 22.6%;
        padding-left: 7.3%;
        padding-top: 59px;
        background-color: $green-light;

        @include mq(1400px) {
            padding-left: 3%;
        }

        @include mq($xl) {
            width: 32.6%;
        }
    }

    .ant-tabs-content-holder {
        width: 77.4%;
        padding: 74px 50px 50px 69px;

        @include mq($xl) {
            width: 67.4%;
        }

        @include mq($lg) {
            padding: 50px 30px;
        }
    }

    @include mq(640px) {
        padding-top: 50px;
    }
}

.loan-terms__tab-content-box {
    display: flex;

    @include mq($lg) {
        flex-direction: column;
    }

    @include mq(640px) {
        margin-top: 10px;
    }
}

.loan-terms__tab-content-left-title {
    font-size: 20px;
    font-weight: 500;
    color: $outher-space;
    line-height: 22px;
    letter-spacing: 0;
    margin-bottom: 10px;
    margin-left: 0;
}

.loan-terms__tab-content-left {
    width: 27%;
    margin-right: 112px;

    @include mq($xl) {
        margin-right: 45px;
    }

    @include mq($lg) {
        width: 100%;
    }

    @include mq(640px) {
        margin-right: 0;
        background-color: $white;
        padding: 30px 20px 0 20px;
    }
}

.loan-terms__tab-content-right {
    width: 47.3%;

    @include mq($xl) {
        width: 65.2%;
    }

    @include mq($lg) {
        width: 100%;
    }
}

.loan-terms__tab-content-form-box {
    box-shadow: 0px 50px 99px #01084c0d;
    border: 1px solid #e7e9f2;
    padding: 37px 47px 26px 48px;
    border-radius: 2px;
    margin-bottom: 30px;

    @include mq(640px) {
        padding: 20px;
        border: 0;
        background-color: $white;
    }
}

.loan-terms__tab-submit-wrapp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 49px;

    @include mq($sm) {
        flex-direction: column;
    }
}

.loan-terms__button-back {
    display: flex;
    align-items: center;
    color: $theme-blue;
    background-color: $white;
    border: 1px solid $theme-blue;
    padding: 10px 69px 10px 54px;
    border-radius: 2px;
    justify-content: center;
    margin-right: 8px;
    transition: $dur $ease;

    svg {
        width: 15px;
        fill: $theme-blue;
        transform: rotate(180deg);
        margin-right: 11px;
        pointer-events: none;
        transition: $dur $ease;
    }

    @include hover {
        transition: $dur $ease;
        background-color: $theme-blue;
        color: $white;
        transition: $dur $ease;

        svg {
            fill: $white;
            transition: $dur $ease;
        }
    }

    @include mq($sm) {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.loan-terms__button-submit-btn {
    position: relative;

    input {
        border: 1px solid $theme-blue;
        background-color: $theme-blue;
        color: $white;
        border-radius: 2px;
        padding: 10px 45px 10px 25px;
        transition: $dur $ease;
    }

    svg {
        width: 15px;
        fill: $white;
        position: absolute;
        right: 21px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        transition: $dur $ease;
    }

    @include hover {
        input {
            transition: $dur $ease;
            background-color: $white;
            color: $theme-blue;
            transition: $dur $ease;
        }

        svg {
            fill: $theme-blue;
            transition: $dur $ease;
        }
    }

    @include mq(640px) {
        border: 0;
    }
}

//mobile
.loan-terms__mobile {
    padding: 0 20px;

    .ant-collapse {
        width: 100%;
        border: 0;
        background-color: transparent;
    }

    .ant-collapse > .ant-collapse-item {
        border: 0;
        background-color: transparent;
    }

    .ant-collapse-header {
        pointer-events: none;

        .ant-collapse-expand-icon {
            display: none;
        }

        .ant-collapse-header-text {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
        }

        @include mq(640px) {
            border-radius: 4px;
        }
    }

    .ant-collapse-content {
        border: 0;
        background-color: transparent;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
    }

    .ant-collapse-item.ant-collapse-item-active {
        .ant-collapse-header {
            background-color: #e3f1dc;
        }

        .ant-collapse-header-text {
            color: #548943;
        }
    }
}
