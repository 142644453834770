/*--------------------------------------------------------------
## Loan Card
--------------------------------------------------------------*/

// .infinite-scroll__inner {
// 	@include mq(1199px) {
// 		display: flex;
// 		justify-content:flex-start;
// 		flex-wrap: wrap;
// 	}
// }

// .loan-card__wrapper {
// 	@include mq(1199px) {
// 		width: 33.33%;
// 	}
// }

.loan-card {
    width: 100%;
    min-height: 111px;
    box-shadow: 0px 3px 17px -7px rgba($black, 0.2);
    border-radius: 4px;
    background-color: $white;
    margin-bottom: 11px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 25px 54px 25px 34px;
    cursor: pointer;
    transition: $dur $ease;
    position: relative;

    @include hover {
        background-color: $green-medium;
        box-shadow: none;
    }

    @include mq(1199px) {
        flex-wrap: wrap;
        padding: 25px 24px 25px 24px;
    }
}

.loan-card--alt {
    padding: 24px 35px 20px 19px;

    @include mq(640px) {
        padding: 24px 19px 20px;
    }
}

.loan-card__item-title {
    font-size: 12px;
    color: $gray-medium;
    font-weight: 600;
}

.loan-card__item-value {
    font-size: 14px;
    color: $outher-space;
    font-weight: 500;
    padding-right: 10px;
    font-variant: normal;
    font-feature-settings: normal;
    @include word-row-clip(2);

    .loan-card__item-inline-value {
        display: inline-block;
        padding-right: 4px;
    }
}

.loan-card__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-right: 0;
    width: 100%;
    padding-right: 20px;

    @include mq(1199px) {
        margin-bottom: 15px;
        max-width: 170px !important;
    }

    @include mq(640px) {
        max-width: 33% !important;
    }

    @include mq(460px) {
        max-width: 50% !important;
    }

    &.loan-card__item--id {
        .loan-card__item-value {
            font-weight: 600;
        }
    }

    &.loan-card__item--address {
        min-width: 240px;

        @include mq(1280px) {
            min-width: 160px;
        }

        @include mq(1199px) {
            max-width: auto;
        }
    }
    // // Styles for users
    // @include with-count(8) {
    //     &.loan-card__item--id {
    //         max-width: 105px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--address {
    //         max-width: 200px;
    //         width: 100%;
    //         padding-right: 50px;

    //         @include mq(640px) {
    //             padding-right: 109px;
    //             max-width: 100% !important;

    //             .loan-card__item-value {
    //                 display: block;
    //                 overflow: visible;
    //                 color: $theme-green;
    //                 font-weight: 700;
    //             }
    //         }
    //     }

    //     &.loan-card__item--asset {
    //         max-width: 123px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--units {
    //         max-width: 87px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--loan-type {
    //         max-width: 120px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--transaction {
    //         max-width: 149px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--amount {
    //         max-width: 110px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--messages {
    //         margin-right: 10px;
    //     }
    // }

    // // Styles for admins
    // @include with-count(10) {
    //     &.loan-card__item--id {
    //         max-width: 100px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--address {
    //         max-width: 170px;
    //         width: 100%;
    //         padding-right: 15px;

    //         @include mq(640px) {
    //             padding-right: 109px;
    //             max-width: 100% !important;

    //             .loan-card__item-value {
    //                 display: block;
    //                 overflow: visible;
    //                 color: $theme-green;
    //                 font-weight: 700;
    //             }
    //         }
    //     }

    //     &.loan-card__item--borrower {
    //         max-width: 94px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--lender {
    //         max-width: 120px;
    //         width: 100%;
    //         padding-right: 5px;
    //     }

    //     &.loan-card__item--asset {
    //         max-width: 92px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--units {
    //         max-width: 55px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--loan-type {
    //         max-width: 83px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--transaction {
    //         max-width: 122px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--amount {
    //         margin-right: 25px;
    //         max-width: 74px;
    //     }

    //     &.loan-card__item--messages {
    //         margin-right: 10px;
    //     }
    // }

    // // Styles for super admins
    // @include with-count(12) {
    //     &.loan-card__item--id {
    //         max-width: 120px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--address {
    //         max-width: 209px;
    //         width: 100%;
    //         padding-right: 15px;

    //         @include mq(640px) {
    //             padding-right: 109px;
    //             max-width: 100% !important;

    //             .loan-card__item-value {
    //                 display: block;
    //                 overflow: visible;
    //                 color: $theme-green;
    //                 font-weight: 700;
    //             }
    //         }
    //     }

    //     &.loan-card__item--borrower {
    //         max-width: 88px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--lender {
    //         max-width: 113px;
    //         width: 100%;
    //         padding-right: 5px;
    //     }

    //     &.loan-card__item--asset {
    //         max-width: 92px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--units {
    //         max-width: 55px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--loan-type {
    //         max-width: 83px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--transaction {
    //         max-width: 122px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--amount {
    //         max-width: 96px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--originator {
    //         max-width: 104px;
    //         width: 100%;
    //     }

    //     &.loan-card__item--processor {
    //         max-width: 69px;
    //         width: 100%;
    //     }
    //     // &.loan-card__item--messages {
    //     // 	margin-right: 10px;
    //     // }
    // }
}

.loan-card__item-bundle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 195px;
    width: 100%;
    margin-left: auto;

    @include mq(1280px) {
        max-width: 150px;
    }

    @include mq(640px) {
        max-width: 100%;
        justify-content: flex-start;

        .loan-card__item {
            max-width: 179px !important;
        }
    }

    @include mq(440px) {
        .loan-card__item {
            max-width: 100% !important;
        }
    }
}

.loan-card__item.loan-card__item--messages {
    @include mq(1199px) {
        margin-left: auto;
    }

    @include mq(640px) {
        margin-left: 0;
    }
}

.loan-card__item.loan-card__item-status {
    // @include mq(1199px) {
    // 	margin-left: 20px;
    // }

    @include mq(640px) {
        margin-bottom: 0;
    }
}

.loan-card__item--messages {
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    margin-right: 0;
    padding-top: 9px;
}

.loan-card__item-icon {
    margin-top: 3px;
    margin-right: 8px;
}

.loan-card__item-num {
    width: 28px;
    height: 28px;
    background-color: $red;
    border-radius: 50%;
    font-size: 16px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loan-card__item-status {
    width: 117px;
    height: 44px;
    background-color: var(--status-bg-color);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9px;
    padding: 0 5px;
    margin-left: auto;
    text-align: center;

    @include mq(1280px) {
        // width: 80px;
        height: 33px;
    }

    @include mq(640px) {
        width: 100%;
        height: 44px;
    }
}

.loan-card__item-status.loan-card__item-status--small {
    padding: 0px 4px;
    width: auto;
    height: auto;
    margin-left: 0;
    margin-top: 3px;
    white-space: nowrap;

    .loan-card__item-status-val {
        font-size: 10px;
    }
}

.loan-card__item-status-val {
    font-size: 12px;
    font-weight: 700;
    color: var(--status-color);
}

.loan-card__item-status--blue {
    --status-bg-color: #e7e9f2;
    --status-color: #010e7e;
}

.loan-card__item-status--yellow {
    --status-bg-color: #fef0d4;
    --status-color: #be7f00;
}

.loan-card__item-status--green {
    --status-bg-color: #bfdbb1;
    --status-color: #548943;
}

.loan-card__item-status--gray {
    --status-bg-color: #c8cbd1;
    --status-color: #2f353b;
}

// .loan-card__item-value-street {
//     display: block;
// }
