/*--------------------------------------------------------------
## Base Layout
--------------------------------------------------------------*/
html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    height: 100%;
    font-family: $font-primary;
}

html,
body {
    box-sizing: border-box;

    a {
        transition: $dur $ease;
        &:active,
        &:hover {
            color: $theme-green;
        }
        @include hover {
            color: $green-spring;
        }
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.main {
    padding: 66px 0 0;
    background-color: $green-light;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
}

main {
    min-height: calc(100vh - 165px);
    width: 100%;

    @include mq(362px) {
        min-height: calc(100vh - 228px);
    }
}

/*--------------------------------------------------------------
# Safari
--------------------------------------------------------------*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    main {
        @include mq(1030px) {
            min-height: calc(100vh - 200px);
        }
    }
}

.container {
    // max-width: map-get($container-max-widths, xl);
    width: 100%;
    // padding: 0 $basePadding;
    margin: 0 auto;
    padding: 0 6%;
    max-width: calc(1670px + 12%);

    // @include mq($lg) {
    //     max-width: map-get($container-max-widths, $lg);
    // }

    // @include mq($md) {
    //     max-width: map-get($container-max-widths, $md);
    // }

    @include mq($sm) {
        max-width: map-get($container-max-widths, $sm);
    }
}

.container--long {
    // max-width: 1418px;
    width: 100%;
    position: relative;
    padding: 0 3%;

    @include mq($sm) {
        padding: 0 6%;
    }
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba($white, 0.7);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: $green-spring;
    color: $black;
    span,
    svg {
        color: $black;
        fill: $black;
    }
}
