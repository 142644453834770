.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
    display: none;
}

.ant-tabs-nav {
    margin: 0 0 8px 0;
}

.ant-tabs-tab {
    padding: 11px 10px;
}

.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 23px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: $black;
}

.ant-tabs-nav-list .ant-tabs-ink-bar {
    background-color: transparent;
    /* background-color: $theme-green; */
}
