// Ant inputs styles
.form-elements__row {
    display: flex;
    .form-element:first-child {
        margin-right: 8px;
    }
    .form-element__error-msg span:last-child {
        font-size: 11px;
        line-height: 11px;
        margin-top: 1px;
    }
    @include mq($xs) {
        display: block;
        .form-element__error-msg span:last-child {
            font-size: 13px;
            line-height: 19px;
            margin-top: 0;
        }
    }
}

.form-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 19px;
    position: relative;

    label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &.form-element--error {
        input,
        .basic-select.ant-select .ant-select-selector {
            background-color: rgba(225, 25, 0, 0.102) !important;
            border: 1px solid #e11900 !important;
        }
    }

    .ant-input,
    .react-date-inputs__inputs-wrapper input {
        width: 100%;
        height: 44px;
        font-size: 12px;
        padding: 0 40px 0 15px;
        font-weight: 500;
        border-radius: 6px;
        border: 1px solid $green-medium;
        background-color: $green-light;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }
        &:hover {
            border-color: rgba($theme-green, 0.4);
        }
        &:focus {
            border-color: #bfdbb1;
            background-color: $white;
            box-shadow: none;
        }
    }

    .ant-input-affix-wrapper,
    .ant-input[name='email'],
    .ant-input-affix-wrapper,
    .ant-input[name='password'] {
        position: relative;
        border: 1px solid $green-medium;
        background-color: $green-light;
        padding: 0 14px;
        border-radius: 6px;

        &:focus {
            background-color: $white;
            border-color: #bfdbb1;
            box-shadow: none;
        }
    }

    .password-icon {
        position: absolute;
        right: 15px;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .form-element__error-msg {
        position: absolute;
        top: 72px;
        left: 0;
        font-size: 13px;
        color: $red;
        font-weight: 600;
        display: flex;
        align-items: center;
        min-height: 20px;

        span {
            color: $red;

            &:nth-child(2) {
                padding-left: 17px;
            }
        }
    }

    .form-element__error-msg--radio {
        top: unset;
        bottom: -28px;
    }


    .basic-select.ant-select {
        width: 100%;
        &.ant-select-focused .ant-select-selector {
            border-color: $green-medium;
        }
        .ant-select-selector {
            height: 44px;
            font-size: 12px;
            padding: 0 40px 0 15px;
            border-radius: 6px;
            border: 1px solid $green-medium;
            background-color: $green-light;
            @include hover {
                border-color: rgba($theme-green, 0.4);
            }
        }
        .ant-select-selection-item {
            display: flex;
            align-items: center;
            font-weight: 500;
        }
        .ant-select-arrow {
            width: 10px;
            right: 14px;
            margin-top: -7px;
        }
    }
}

.form-element__error-msg-icon {
    margin-right: 4px;
    font-size: 12px;
    height: 14px;
    position: absolute;
    top: 3px;

    svg {
        height: 100%;
        color: $red;
        font-weight: 600;
    }
}

.form-element--password {
    .ant-input-suffix {
        margin-left: 0;
    }
    .ant-input-affix-wrapper {
        padding: 0 !important;
        box-shadow: none;
        background-color: transparent;
        border: none;
        &:hover {
            border: none;
        }

        input {
            background-color: $green-light;
            border: 1px solid $green-spring;
            border-radius: 6px;
            padding: 0 14px;
            position: relative;
        }
    }
}

.form-element--radio {
    .ant-radio-group {
        width: 100%;
    }
    .ant-radio {
        display: none;
    }
    .ant-radio-wrapper {
        display: block;
        width: 100%;
        border-radius: 6px;
        border: 1px solid transparent;
        padding: 5px 0 10px;
        margin-bottom: 13px;
        background-color: $green-light;
        text-align: center;
        transition: border 0.2s ease;
        &.ant-radio-wrapper-checked {
            background-color: $green-spring;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include hover {
            border: 1px solid $green-spring;
        }
    }
    .form-element__error-msg {
        bottom: -22px;
        top: auto;
        margin-bottom: 0;
    }
}

.form-element--date {
    .form-element__error-msg {
        top: 46px;
        @media screen and (max-width: 512px) {
            top: 102px;
        }
    }
}

.form-element--address {
    .css-1wy0on6 {
        display: none;
    }
    .css-2b097c-container {
        width: 100%;
    }
    .css-1hwfws3 {
        cursor: text;
        padding: 6px 15px;
        border-radius: 6px;
        border: 1px solid $green-medium;
        transition: 0.2s ease;
        &:hover {
            border: 1px solid rgba($theme-green, 0.4);
        }
    }
    .css-yk16xz-control {
        background-color: $green-light;
        border: none;
    }
    .css-1pahdxg-control {
        background-color: $white;
        border: none !important;
        box-shadow: none !important;
        border-color: $green-spring;
    }
    div[id^='react-select-address-option']:hover {
        background-color: $green-light;
    }
    div[id^='react-select-address-option'] {
        background-color: $white;
    }
    &.form-element--error {
        input {
            border: none !important;
            background-color: transparent !important;
        }
        .css-yk16xz-control {
            background-color: transparent;
        }
        .css-1hwfws3 {
            border: 1px solid #e11900 !important;
            background-color: rgba(225, 25, 0, 0.102) !important;
        }
    }
}

.ant-input-affix-wrapper-focused {
    background-color: transparent;
    box-shadow: none;
    border: none;
}

// Styles for ant select dropwdonw
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $green-light !important;
    .ant-select-item-option-content {
        color: $black;
    }
}

.ant-select-item-option-active {
    background-color: transparent !important;
    @include hover {
        background-color: $green-light !important;
    }
}

.prevent-scroll {
    overflow: hidden;
}

// notifications
.ant-message-notice {
    .ant-message-notice-content {
        padding: 0;
    }

    .ant-message-custom-content {
        padding: 9px 24px;
        position: relative;

        @include mq($lg) {
            padding: 9px 15px;
        }
        span {
            font-family: $font-primary;
            font-size: 14px;
            line-height: 22px;
            font-weight: bold;
            padding-left: 25px;
            text-align: left;
            display: flex;

            @include mq($lg) {
                padding-left: 20px;
            }
        }

        svg {
            font-size: 15px;
            height: 15px;
            position: absolute;
            top: 13px;
            left: 22px;

            @include mq($lg) {
                left: 15px;
            }
        }
    }

    .ant-message-success {
        border-left: 3px solid #8cc97b;

        svg {
            fill: #8cc97b;
        }
    }

    .ant-message-error {
        border-left: 3px solid #e11900;
        svg {
            fill: #e11900;
        }
    }

    .ant-message-info {
        border-left: 3px solid #8cc97b;
        svg {
            fill: #8cc97b;
        }
    }

    .ant-message-warning {
        border-left: 3px solid #faad14;
        svg {
            fill: #faad14;
        }
    }
}

.login {
    .select-wrapper {
        .select-wrapper.select-wrapper__default {
            border-color: $green-medium;
        }
        .css-1okebmr-indicatorSeparator {
            display: none;
        }
        [class*='control'] {
            background-color: $green-light;
        }
        [class*='option'] {
            @include hover {
                background-color: $green-light;
            }
        }
        @include hover {
            border-color: rgba($theme-green, 0.5);
        }
    }
    &.submit-confirmation {
        /* padding-top: calc((100vh - 450px) / 2); */
        padding: 45px 0 40px;
        a {
            color: $green-spring;
            @include hover {
                color: $theme-green;
            }
        }
        .login__title {
            margin-bottom: 50;
        }
        .login__subtitle {
            max-width: none;
            & + .login__subtitle {
                max-width: 490px;
            }
        }
    }
}

.react-date-inputs__inputs-wrapper {
    display: flex;
    position: relative;
    input {
        margin-right: 35px;
        text-align: left;
        &:last-child {
            margin-right: 0;
            width: 140px;
        }
    }
    &::after,
    &::before {
        content: '/';
        position: absolute;
        color: $gray-medium;
        top: 2px;
        left: 92px;
        font-size: 26px;
        font-weight: 300;
    }
    &::before {
        left: auto;
        right: 150px;
    }
    @media screen and (max-width: 512px) {
        flex-wrap: wrap;
        input {
            text-align: center;
            padding: 0 !important;
            &:nth-child(2) {
                margin-right: 0;
            }
            &:not(:last-child) {
                width: calc(50% - 17.5px);
                margin-bottom: 12px;
            }
            &:last-child {
                width: 100%;
            }
        }
        &::after {
            left: 48%;
        }
        &::before {
            display: none;
        }
    }
}

#react-select-address-input {
    opacity: 1 !important;
}

//Select Element
.select-element {
    .ant-select-arrow {
        user-select: none;
        top: 0;
        right: 0;
        height: 44px;
        display: flex;
        align-items: center;
        margin-top: 0;
        width: 36px;
        justify-content: center;

        .select-arrow__wrapper {
            height: 100%;
            width: 100%;
            padding-left: 2px;
            background-color: #fff !important;
        }

        .select-arrow__wrapper-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            padding: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        svg {
            transform: rotate(-90deg);
            height: 16px;
        }
    }

    .ant-select-single {
        text-align: center;
        width: 180px;
    }

    .ant-select-selector {
        height: 44px !important;
        border: none !important;
        border-radius: 4px !important;
    }

    .ant-select-selection-item {
        font-size: 12px;
        font-weight: 600;
        line-height: 17px;
        color: #be7f00;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 38px !important;
    }
}

.select-element.loan-status--phase-1 {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        // background-color: #fbb !important; // TODO: need color scheme
        background-color: #e7e9f2 !important; // TODO: need color scheme

        .ant-select-selection-item {
            color: #010e7e !important;
        }
    }

    .ant-select-arrow {
        .select-arrow__wrapper-box {
            background-color: #e7e9f2 !important;
        }
    }

    .ant-select-selection-item {
        color: $red;
    }

    svg {
        fill: #010e7e !important;
    }
}

.select-element.loan-status--phase-2 {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #fef0d4 !important; // TODO: need color scheme

        .ant-select-selection-item {
            color: #be7f00 !important;
        }
    }

    .select-arrow__wrapper-box {
        background-color: #fef0d4 !important;
    }

    svg {
        fill: #be7f00;
    }
}

.select-element.loan-status--phase-3 {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #e3f1dc !important; // TODO: need color scheme

        .ant-select-selection-item {
            color: #548943 !important;
        }
    }

    .select-arrow__wrapper-box {
        background-color: #e3f1dc !important;
    }

    svg {
        fill: #548943 !important;
    }
}

.select-element.loan-status--phase-4 {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #c8ccd2 !important; // TODO: need color scheme

        .ant-select-selection-item {
            color: #2f353b !important;
        }
    }

    .select-arrow__wrapper-box {
        background-color: #c8cbd1 !important;
    }

    svg {
        fill: #2f353b !important;
    }
}

.select-element-dropdown {
    width: 180px !important;

    .ant-select-item {
        font-size: 12px;
        font-weight: 600;
        color: #2f353b;
    }

    .ant-select-item-option-selected {
        background-color: #faedd2 !important;

        .ant-select-item-option-content {
            color: #be7f00 !important;
        }
    }
}

.select-element-dropdown.loan-status--phase-1 {
    .ant-select-item-option-selected {
        background-color: #e7e9f2 !important;

        .ant-select-item-option-content {
            color: #0107ee !important;
        }
    }
}

.select-element-dropdown.loan-status--phase-2 {
    .ant-select-item-option-selected {
        background-color: #fef0d4 !important;

        .ant-select-item-option-content {
            color: #be7f00 !important;
        }
    }
}

.select-element-dropdown.loan-status--phase-3 {
    .ant-select-item-option-selected {
        background-color: #e3f1dc !important;

        .ant-select-item-option-content {
            color: #548943 !important;
        }
    }
}

.select-element-dropdown.loan-status--phase-4 {
    .ant-select-item-option-selected {
        background-color: #c8cbd1 !important;

        .ant-select-item-option-content {
            color: #2f353b !important;
        }
    }
}

.form-element--input-icon .form-element--input-icon--percentage svg {
        position: absolute;
        width: 10px;
        fill: #c8ccd2;
        right: 18px;
        bottom: 15px;
        pointer-events: none;
        z-index: 1;
}

.loan-terms-main {
    footer,
    .main {
        background: linear-gradient(
            90deg,
            rgba(244, 248, 242, 1) 22.6%,
            rgba(255, 255, 255, 1) 22.6%
        );

        @include mq($xl) {
            background: linear-gradient(
                90deg,
                rgba(244, 248, 242, 1) 32.6%,
                rgba(255, 255, 255, 1) 32.6%
            );
        }

        @include mq(640px) {
            background: #f4f8f2;
        }
    }
}

.submit-confirmation-img {
    img {
        display: block;
        margin: 0 auto;

        @include mq(450px) {
            width: 100%;
        }
    }
}

.submit-confirmation-btn {
    max-width: 200px;
    margin: 0 auto;
    color: $white !important;
    background-color: $theme-blue !important;
    border: 2px solid $theme-blue !important;
    font-weight: 500;

    @include hover {
        color: $theme-blue !important;
        background-color: transparent !important;
    }
}
