h1,
h2,
h3,
h4,
h5,
h6 {
    color: $outher-space;
    font-weight: 400;
}

h1 {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.6px;
}

h2 {
    font-size: 50px;
    letter-spacing: -0.84px;
    margin-bottom: 25px;
    margin-left: -2px;
    line-height: 50px;
}

h3 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.35px;
    color: $black;
}

h4 {
    font-size: 18px;
    font-weight: 600;
}

p,
label,
span,
input {
    color: $outher-space;
}

/*--------------------------------------------------------------
## basic-content
--------------------------------------------------------------*/
.basic-content {
    padding-top: 32px;
    padding-bottom: 20px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-primary;
        font-weight: 600;
        line-height: 1.05;
        color: $outher-space;
        margin-bottom: 15px;
        text-transform: none;
    }

    h1 {
        font-size: 55px;

        @include mq(1600px) {
            font-size: 40px;
        }

        @include mq(1440px) {
            font-size: 36px;
        }

        @include mq($xl) {
            font-size: 32px;
        }

        @include mq($lg) {
            font-size: 30px;
        }

        @include mq($md) {
            font-size: 28px;
        }
    }

    h2 {
        font-size: 40px;

        @include mq(1600px) {
            font-size: 30px;
        }

        @include mq(1440px) {
            font-size: 28px;
        }

        @include mq($xl) {
            font-size: 26px;
        }
    }

    h3 {
        font-size: 30px;

        @include mq(1440px) {
            font-size: 28px;
        }

        @include mq($xl) {
            font-size: 26px;
        }

        @include mq(1024px) {
            font-size: 25px;
        }

        @include mq($lg) {
            font-size: 22px;
        }

        @include mq($md) {
            font-size: 20px;
        }
    }

    h4 {
        font-size: 20px;

        @include mq(1440px) {
            font-size: 30px;
        }

        @include mq($xl) {
            font-size: 28px;
        }

        @include mq(1024px) {
            font-size: 26px;
        }

        @include mq($lg) {
            font-size: 25px;
        }

        @include mq($md) {
            font-size: 24px;
        }
    }

    h5 {
        font-size: 20px;
    }

    h6 {
        font-size: 16px;
    }

    p {
        font-family: $font-primary;
        line-height: 1.8;
        font-size: 16px;
        margin-bottom: 20px;
        color: $outher-space;

        @include mq($xl) {
            line-height: 1.6;
            br {
                display: none;
            }
        }

        @include mq($lg) {
            font-size: 14px;
            margin-bottom: 15px;
            line-height: 1.5;
        }
    }
    span {
        display: block;
        font-family: $font-primary;
        line-height: 1.5;
        font-size: 14px;
        color: $outher-space;
        margin-bottom: 20px;

        @include mq($lg) {
            margin-bottom: 15px;
        }
    }

    li {
        font-family: $font-primary;
        line-height: 1.8;
        font-size: 16px;
        color: $outher-space;

        @include mq($lg) {
            font-size: 14px;
            line-height: 1.5;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul,
    ol {
        margin-bottom: 20px;

        @include mq($lg) {
            margin-bottom: 15px;
        }

        li {
            margin-bottom: 7px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    ul {
        margin-left: 35px;

        @include mq($lg) {
            margin-left: 25px;
        }

        li {
            list-style: disc;
        }
    }

    ol {
        margin-left: 43px;
        list-style-type: upper-roman;

        @include mq($lg) {
            margin-left: 25px;
        }

        li {
            list-style-type: upper-roman;
            position: relative;
        }
    }

    .alphabeth-list {
        list-style-type: lower-alpha;

        li {
            list-style-type: lower-alpha;
            position: relative;
        }
    }

    a {
        font-size: 16px;
        line-height: 1.3;
        font-family: $font-primary;
        color: $outher-space;
        transition: $dur $ease;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background-color: $outher-space;
            bottom: 0;
            left: 0;
            transition: $dur $ease;
            height: 1px;
            width: 100%;
        }

        @include mq($lg) {
            font-size: 14px;
        }

        @include hover {
            &::before {
                width: 0;
                opacity: 0;
            }
        }
    }
}
