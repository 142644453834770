/*--------------------------------------------------------------
## Header
--------------------------------------------------------------*/

.header {
    min-height: 66px;
    background-color: $white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // box-shadow: 0 0 9px 0 rgba($black, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.header__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.header__logo-wrapper {
    display: flex;
    align-items: center;
    margin-right: 33px;
    max-width: 122px;
    height: 27px;
}

.header__logo {
    width: 100%;
}

.nav-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    transition: $dur cubic-bezier(0.17, 0.67, 0.83, 0.67);

    @include mq(1024px) {
        position: fixed;
        top: 0;
        right: -100%;
        width: fit-content;
        min-width: 320px;
        height: 100%;
        background-color: $white;
        flex-direction: column;
        align-items: center;
        padding: 68px 0 0;
        overflow-y: scroll;
        z-index: 10;
    }

    @include mq(400px) {
        width: 100%;
        min-width: auto;
    }
}

.nav-wrapper--active {
    right: 0;
}

.nav-wrapper__account-mobile-wrapper {
    display: none;
    flex-direction: column;
    align-items: center;

    @include mq(1024px) {
        display: flex;

        .header__account-dropdown-name {
            padding: 0 15px;
        }

        .header__account-dropdown-email {
            padding: 0 15px;
            margin-bottom: 21px;
            text-align: center;
            word-break: break-all;
        }
    }
}

.header__nav-link-icon {
    display: flex;
    align-items: center;

    svg {
        height: 12px;
        width: auto;
        color: $black;
        fill: $black;
        margin-left: 5px;
        transition: $dur $ease;
    }
}

.header__nav {
    padding: 0 32px;
    position: relative;
    display: flex;
    align-items: center;

    a {
        //Changes below should stay commented for now. The code will be used later when needed.
        font-size: 14px;
        color: $black;
        margin-right: 38px;
        padding: 7px 9px;
        border-radius: 4px;
        /* background-color: $green-medium; */
        font-weight: 600;
        cursor: default;

        /* &.active {
            background-color: $green-medium;
            color: $black;
        } */
        &:hover {
            /* background-color: $green-medium; */
            /* color: $theme-green; */
            color: $black;
        }

        @include mq(1024px) {
            width: 100%;
            height: 68px;
            padding: 7px 59px;
            background-color: white;
            border-radius: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            font-weight: 700;
            border-top: 1px solid #f5f7fa;
            border-bottom: 1px solid #f5f7fa;

            &.active {
                background-color: $green-medium;
                color: $theme-green;
            }

            &:hover {
                background-color: $green-medium;
                color: $theme-green;

                svg {
                    color: $theme-green;
                    fill: $theme-green;
                }
            }
        }
    }

    @include mq(1024px) {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
    }

    .messages-counter {
        min-width: 19px;
        min-height: 19px;
        background-color: $red;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: $white;
        font-size: 10px;
        line-height: 19px;
        margin-left: 9px;
        transition: $dur $ease;
    }

    &::before {
        content: '';
        width: 1px;
        height: 35px;
        background-color: $gray;
        position: absolute;
        top: -6px;
        left: 0;

        @include mq(1024px) {
            display: none;
        }
    }
}

.nav-wrapper__button-mobile-wrapper {
    display: none;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    padding: 0 33px 46px;

    @include mq(1024px) {
        display: flex;
    }
}

.header__account {
    background-color: $green-light;
    border-radius: 20px;
    padding: 9px 33px 9px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    position: relative;
    cursor: pointer;
    transition: $dur $ease;
    z-index: 11;

    &:hover {
        // Add subtle box shadow
        box-shadow: 0 0 5px 5px rgba($black, 0.1);
    }

    @include mq(1024px) {
        display: none;
    }
}

.header__account-img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 10px;
}

.header__account-name {
    font-size: 14px;
    font-weight: 500;
}

.header__account-icon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
    }
}

// ----- Header Dropdwon styels ------------------------------------------------

.header__account-dropdown {
    position: absolute;
    top: 40px;
    right: 13px;
    min-width: 301px;
    border-radius: 4px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 31px 22px;
    z-index: 11;
    opacity: 0;
    pointer-events: none;
    transition: $dur $ease;

    &::after {
        content: '';
        position: absolute;
        top: -6px;
        right: 49px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $white;
    }
}

.header__account-dropdown--active {
    top: 56px;
    opacity: 1;
    pointer-events: all;
}

.header__account-dropdown-img-wrapper {
    margin-bottom: 19px;
    width: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__account-dropdown-name {
    font-size: 22px;
    font-weight: 700;
}

.header__account-dropdown-email {
    font-size: 17px;
    letter-spacing: 0px;
    margin-bottom: 30px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.3);
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: $dur $ease;
}

.overlay--active {
    opacity: 1;
    pointer-events: all;
}

.header__login-btn {
    width: 95px;
    height: 38px;
    border: 1px solid $gray;
    background-color: $white;
    letter-spacing: 0px;
    padding-bottom: 1px;
    color: $theme-green;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    transition: $dur $ease;

    @include hover {
        background-color: $theme-green;
        color: $white;
    }
}

.hamburger-icon,
.nav-wrapper__close-btn {
    display: none;
}

@include mq(1024px) {
    .hamburger-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 20px;
        height: 34px;
        background-color: transparent;
        margin-left: auto;
        position: relative;

        span {
            width: 70%;
            height: 2px;
            background-color: $theme-green;

            &::before,
            &::after {
                content: '';
                width: 100%;
                height: 2px;
                background-color: $theme-green;
                position: absolute;
            }

            &::before {
                top: 9px;
                right: 0;
            }

            &::after {
                bottom: 9px;
                right: 0;
            }
        }
    }

    .nav-wrapper__close-btn-mobile {
        position: absolute;
        left: 39px;
        top: 23px;
        width: 32px;
        height: 32px;
        opacity: 0.3;
        transform: rotate(-47deg);
    }

    .nav-wrapper__close-btn-mobile:hover {
        opacity: 1;
    }

    .nav-wrapper__close-btn-mobile,
    .nav-wrapper__close-btn-mobile::after {
        position: absolute;
        content: '';
        height: 27px;
        width: 3px;
        background-color: #333;
    }

    .nav-wrapper__close-btn-mobile::before {
        transform: rotate(45deg);
        right: 15px;
    }

    .nav-wrapper__close-btn-mobile::after {
        transform: rotate(-90deg);
    }
}
