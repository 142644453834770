/*--------------------------------------------------------------
## Buttons
--------------------------------------------------------------*/

.login,
.forgot-password {
    padding: 125px 0 40px;

    @include mq(640px) {
        padding: 44px 0 10px;
    }
}

.login__title,
.forgot-password__title {
    text-align: center;
    margin-bottom: 17px;
}

.forgot-password__title {
    margin-bottom: 22px;
}

.login__subtitle,
.forgot-password__subtitle {
    font-size: 16px;
    // color: #8a94a6;
    color: $gray-medium;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 0 auto 30px;
    max-width: 410px;
    &.small {
        font-size: 13px;
        margin-top: 20px;
        max-width: none;
        letter-spacing: 0;
        @include mq(470px) {
            padding: 0 20px;
        }
    }
}

.login__subtitle--negative-margin {
    margin-top: -20px;
}

.login__subtitle--middle {
    max-width: none;
    margin-bottom: 30px;
}

.login__box,
.forgot-password__box {
    max-width: 454px;
    width: 100%;
    background-color: $white;
    box-shadow: 0px 50px 99px #01084c0d;
    border: 1px solid #e7e9f2;
    border-radius: 4px;
    padding: 48px 39px 52px;
    margin: 0 auto;

    @include mq(640px) {
        padding: 30px 41px 35px;
    }

    @include mq(411px) {
        padding: 30px 15px 35px;
    }
}

.forgot-password__box {
    padding: 41px 39px 33px;
}

.login__btn {
    margin-top: 25px;

    @include mq(332px) {
        margin-top: 34px;
    }
}

.forgot-password__btn {
    margin-top: 38px;
}

.login__footer,
.forgot-password__footer {
    text-align: center;
    font-size: 12px;
    margin-top: 32px;
    font-weight: 500;

    p {
        margin-bottom: 6px;
    }

    a {
        color: $theme-green;
        font-weight: 700;
    }
}

.login__error {
    font-size: 13px;
    color: $red;
    font-weight: 600;
    margin-top: 0;
    display: flex;
    position: relative;
    line-height: 1.3;

    .form-element__error-msg-icon {
        top: 1px;
    }

    span {
        color: $red;

        &:nth-child(2) {
            padding-left: 17px;
        }
    }
}

.login__error-wrap {
    display: flex;
    align-items: flex-start;

    .form-element__error-msg-icon {
        margin-right: 4px;
        font-size: 12px;
        height: 14px;
        display: flex;

        svg {
            height: 100%;
            color: $red;
            font-weight: 600;
        }
    }
}
