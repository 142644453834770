/*--------------------------------------------------------------
## My Loans
--------------------------------------------------------------*/

.my-loans {
    padding: 32px 0 0;
}

.my-loans__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;

    @include mq(640px) {
        flex-direction: column;
        padding: 0;
        align-items: flex-start;
    }
}

.my-loans__right-side {
    position: relative;
    @include mq(640px) {
        width: 100%;
    }

    &::after {
        content: '';
        height: 89%;
        width: 1px;
        background-color: #e1e3e5;
        position: absolute;
        right: 147px;
        top: 3px;

        @include mq($lg) {
            height: 32px;
            top: auto;
            bottom: 5px;
        }

        @include mq(640px) {
            display: none;
        }
    }
}

.my-loans__right-side-clear {
    font-size: 12px;
    color: $theme-green;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    margin-right: 8px;
    font-weight: 600;
    white-space: nowrap;
    padding: 0;

    /* @include mq(411px) {
        position: absolute;
        bottom: -29px;
        left: 2px;
    } */
}

.my-loans__title {
    font-size: 24px;
    color: $black;
    font-weight: bold;
    letter-spacing: -0.1px;
    position: relative;
    display: inline-block;
    margin: 0;
}

.my-loans__title-counter {
    background-color: $green-medium;
    color: $black;
    border-radius: 50%;
    font-size: 13px;
    padding: 2px;
    min-width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -22px;
    top: -3px;
}

.my-loans__right-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include mq($lg) {
        flex-direction: column;
        align-items: flex-end;
    }
}

.select-wrapper {
    margin: 0 29px 0 0;
    /* width: 170px; */
    width: 160px;
    transition: $dur $ease;
    height: 44px;

    svg {
        color: #2e3032;
    }

    &.active [class*='control'] {
        border-color: $theme-green !important;
    }

    &.select-wrapper__default {
        border: 1px solid #d9d9d9;
        width: 100%;
        margin-bottom: 0;
        border-radius: 6px;
        [class*='control'] {
            border: none !important;
        }
    }

    & [class*='control'] {
        height: 100%;
        border-radius: 6px;
        border-color: $green-spring !important;
    }

    & [class*='-menu'] {
        box-shadow: 0px 3px 6px #00000021;
        border-radius: 4px;

        & > div {
            padding: 0;
            max-height: 340px;
            border-radius: 4px;
        }
    }

    & [class*='option'] {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 13px 15px;
        cursor: pointer;
        background-color: transparent !important;
        &:hover label {
            color: $black;
        }

        label {
            font-size: 12px;
            margin-left: 25px;
        }

        input {
            accent-color: $theme-green;
        }
    }

    .select-control-status-indicator {
        background-color: $theme-green;
        border-radius: 50%;
        font-size: 13px;
        padding: 2px;
        min-width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 6px;
        color: $white;
    }

    // &.ant-select-open {
    //     .ant-select-arrow svg {
    //         transform: rotateX(180deg);
    //     }
    // }

    // &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //     height: 44px;
    //     border-radius: 6px;
    //     border: 1px solid $theme-green !important;
    // }

    // .ant-select-selection-search,
    // .ant-select-selection-item {
    //     display: flex;
    //     align-items: center;
    //     color: #2f353b;
    //     font-weight: 500;
    // }

    // &.ant-select {
    //     .ant-select-selector {
    //         box-shadow: none !important;
    //     }
    // }

    @include mq(640px) {
        width: 180px;
        margin: 0 20px 0 0;
    }

    @include mq($sm) {
        width: 100%;
        margin: 0 0 19px;
    }
}

.select-wrapper.disabled {
    div {
        background-color: $gray;
        color: #8a94a6;
        border-color: $gray !important;
    }
}

.ant-select-arrow {
    right: 8px;
    margin-top: -8px;
    @include mq(411px) {
        right: 7px;
    }

    .anticon {
        font-size: 12px;
        color: $outher-space;
    }

    svg {
        transition: $dur $ease;
    }
}

.my-loans__select-button-wrapper {
    .btn {
        @include mq(640px) {
            min-width: 110px;
        }
    }

    @include mq(640px) {
        width: 50%;
    }

    @include mq($sm) {
        width: 100%;
    }
}

// .scrollbar-container {
//     box-shadow: 0 0 22px -14px rgba($black, 0.2);
// }

.infinite-scroll__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $athens-gray;
    svg {
        font-size: 30px;
    }
}

.ant-select-dropdown {
    border-radius: 4px;
    padding: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.129);

    .ant-select-item-option-content {
        padding: 6px 0;
        color: #2f353b;
        font-weight: 500;
    }
}

.ant-select-item-option-selected {
    .ant-select-item-option-content {
        color: $theme-green;
    }
}

.my-loans__subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    @include mq(640px) {
        padding: 0;
    }

    @include mq(430px) {
        padding: 0;
        flex-direction: column;
        align-items: flex-end;
    }
}

.my-loans__subheader-tabs-right-wrapper {
    font-size: 12px;

    @include mq(430px) {
        margin-top: 16px;
    }
}

.my-loans__subheader-tabs-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $white;

    @include mq(430px) {
        width: 100%;
    }
}

.my-loans__subheader-tab {
    color: $outher-space;
    background-color: transparent;
    border: none;
    /* border-bottom: 2px solid transparent; */
    padding: 7px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $dur $ease;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    margin-left: 5px;

    &::before {
        position: absolute;
        content: '';
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        background-color: #e1e3e5;
        height: 18px;
    }

    &:first-child {
        margin-left: 0;

        &::before {
            display: none;
        }
    }

    &:hover {
        color: $theme-green;
    }

    @include mq(430px) {
        width: 50%;
        padding: 7px 12px;
    }
}

.my-loans__subheader-tab--active {
    /* border-bottom: 2px solid $theme-green; */
    color: $theme-green;
    font-weight: 600;
}

.my-loans__select.ant-select-single {
    .ant-select-selector {
        background-color: transparent;
        border: none;
    }
    .ant-select-selection-item {
        font-weight: 600;
        color: $black;
        padding-right: 0;
    }
    .ant-select {
        margin-left: -4px;
    }
}

.my-loans__subheader-tabs-right-wrapper {
    .ant-select {
        font-size: 12px;
        border: none;
    }
    .ant-select-selector {
        background-color: transparent;
        border: none;
    }
    .my-loans__select.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        height: auto;
        border-radius: 0;
        border: none !important;
        background-color: transparent;
    }

    .my-loans__select .ant-select-selection-search,
    .my-loans__select .ant-select-selection-item {
        font-weight: 600;
    }

    .ant-select-arrow {
        right: 0;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-left: 33px;
        padding-right: 0;
    }

    .ant-select-arrow {
        top: 44%;
    }
}

.ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}

.my-loans__subheader-tabs-dropdown {
    border: none;
    min-width: 72px !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
    //   border-color: #d9d9d9 !important;
    box-shadow: none !important;
}

.select-status-wrapper {
    position: relative;
    @include hover {
        background-color: $green-light;
    }
}

.select-checkbox {
    display: none;

    &:checked + .custom-checkbox {
        background-color: $theme-green;
        &::after {
            opacity: 1;
        }
    }
}

.custom-checkbox {
    display: flex;
    align-items: center;
    justify-self: center;
    width: 12px;
    height: 12px;
    border: 1px solid $theme-green;
    position: relative;
    position: absolute;
    top: 16px;
    left: 16px;
    border-radius: 2px;
    cursor: pointer;
    &::after {
        content: '';
        display: inline-block;
        transform: rotate(36deg);
        height: 8px;
        width: 4px;
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        position: absolute;
        top: 0px;
        left: 3px;
        opacity: 0;
        transition: $dur $ease;
    }
}

.load-more-btn__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .infinite-scroll__icon {
        background-color: transparent;
    }

    .ant-btn:hover,
    .ant-btn:active {
        background-color: $theme-green !important;
    }
}

.load-more-btn {
    max-width: 200px;
}

.back-to-top-btn {
    position: fixed;
    bottom: 40px;
    right: -70px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 3px 17px -7px rgba($black, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
    transition: $dur $ease;

    svg {
        fill: $theme-green;
    }

    &:hover {
        box-shadow: 0px 3px 17px -7px rgba($black, 0.5);
    }

    &.show {
        right: 30px;

        @include mq(767px) {
            right: 10px;
        }
    }
}

.my-loans__search {
    margin-bottom: 0 !important;
    margin-right: 14px !important;
    width: 415px !important;

    .ant-select-auto-complete {
        width: 100%;
    }

    input {
        background-color: $white !important;
        display: block !important;
        box-sizing: border-box !important;
        border-radius: 6px !important;
        border: 1px solid $green-spring !important;
        padding: 0 40px 0 50px !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .ant-input-group {
        position: relative;

        .ant-input-group-addon {
            position: absolute;
            left: 11px !important;
            top: 50%;
            transform: translateY(-50%);
            width: 30px !important;
            height: 30px !important;
            z-index: 9 !important;
            pointer-events: none !important;
            background-color: transparent !important;

            button {
                min-width: 30px !important;
                width: 30px !important;
                height: 30px !important;
                background-color: transparent !important;
                box-shadow: none !important;
                pointer-events: none !important;

                @include hover {
                    span {
                        fill: #c8ccd2 !important;
                        color: #c8ccd2 !important;

                        svg {
                            fill: #c8ccd2 !important;
                            color: #c8ccd2 !important;
                        }
                    }
                }
            }
        }
    }

    .anticon-search {
        color: #c8ccd2 !important;
    }

    @include mq($xl) {
        width: 245px !important;
    }

    @include mq($lg) {
        margin-right: 0 !important;
        margin-bottom: 19px !important;
        width: 322px !important;
    }

    @include mq(640px) {
        width: 100% !important;
    }
}

.search-no-results {
    color: #2f353b;
    font-size: 12px;
    font-weight: 500;
    margin-top: 20px;
    display: block;
}

.my-loans__dropdown {
    width: 415px !important;
    top: 153px !important;
    padding-bottom: 10px !important;

    .ant-select-item {
        padding: 3px 12px !important;
    }

    .ant-select-item-option-grouped {
        padding-left: 30px !important;

        &:last-child {
            padding: 3px 12px 11px 30px !important;
        }
    }

    .ant-select-item-option-content {
        font-size: 12px !important;
        color: $outher-space !important;
        font-weight: 500 !important;
        letter-spacing: -0.2px !important;
        white-space: initial !important;
    }

    .ant-select-item-group {
        padding: 7px 20px 4px 20px !important;
        font-size: 10px !important;
        color: $gray-medium !important;
        min-height: 28px !important;
        line-height: 15px !important;

        &:not(:first-child) {
            border-top: 1px solid #e1e3e5 !important;
            margin-top: 12px !important;
            padding: 13px 20px 7px 20px !important;
        }
    }

    .ant-select-item-option-active:hover {
        background-color: $green-medium !important;
    }

    @include mq($xl) {
        width: 245px !important;
        min-width: 245px !important;
    }

    @include mq($lg) {
        min-width: 322px !important;
        width: 322px !important;
        top: 150px !important;
    }

    @include mq(640px) {
        min-width: 88% !important;
        width: 88% !important;
        top: 200px !important;
    }
}

.loan-id {
    margin-right: 20px;
}

.my-loans__right-side-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include mq($sm) {
        flex-wrap: wrap;
        width: 100%;
    }
}

.my-loans__right-side-left {
    @include mq(640px) {
        width: 100%;
    }
}
