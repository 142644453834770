/*--------------------------------------------------------------
## Create Account
--------------------------------------------------------------*/

.create-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 149px 0 85px;

    @include mq(1440px) {
        padding: 100px 0 85px;
    }

    @include mq(640px) {
        padding: 44px 16px 10px;
    }

    @include mq(411px) {
        padding: 44px 0 10px;
    }
}

.create-account__title {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    line-height: 1.1;
    letter-spacing: 0px;
    margin-bottom: 24px;

    @include mq(640px) {
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: -0.6px;
        margin-bottom: 18px;
    }
}

.create-account__subtitle {
    max-width: 450px;
    width: 100%;
    height: 41px;
    background-color: #f5f7fa;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    margin-bottom: 12px;

    @include mq(640px) {
        height: 32px;
        margin-bottom: 7px;
    }
}

.create-account__subtitle-icon {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0px 0px 10px 1px rgba($black, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 22px;

    @include mq(640px) {
        width: 21px;
        height: 21px;
        margin-right: 8px;
        img {
            width: 10px;
        }
    }
}

.create-account__subtitle-text {
    font-size: 15px;
    color: $outher-space;

    a {
        color: $theme-green;
        font-weight: 500;
    }

    @include mq(640px) {
        font-size: 12px;
    }
}

.create-account__wrapper {
    border: 1px solid #e7e9f2;
    background-color: $white;
    max-width: 454px;
    width: 100%;
    height: 424px;
    padding: 30px 38px 35px;

    @include mq(640px) {
        margin-top: 13px;
        padding: 30px 41px 35px;
    }

    @include mq(411px) {
        padding: 30px 15px 35px;
    }
}

.create-account__req {
    margin-bottom: 30px;
    &.benefits-list {
        span {
            font-size: 16px;
            font-weight: 500;
        }
        a {
            color: #c4ddb7;
            @include hover {
                color: $green-spring;
            }
        }
        @include mq(440px) {
            .create-account__req-item {
                font-size: 13px;
            }
            .create-account__req-item-icon {
                width: 13px;
                height: 13px;
                margin-right: 4px;
            }
            a {
                font-size: 12px;
            }
        }
    }
}

.create-account__req-title {
    margin-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    display: block;
}

.create-account__req-item {
    margin-bottom: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.create-account__req-item-icon {
    width: 14px;
    height: 14px;
    background-color: $green-spring;
    border-radius: 50%;
    margin-right: 8px;
    position: relative;

    &::after {
        content: '';
        display: inline-block;
        transform: rotate(45deg);
        height: 7px;
        width: 4px;
        border-bottom: 1px solid $white;
        border-right: 1px solid $white;
        position: absolute;
        left: 5px;
        top: 3px;
    }
}

.create-account__req-item-icon.green {
    background-color: #548943;
}

.create-account__btn {
    width: 13px;
    margin-left: 16px;
}
