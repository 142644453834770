.form-element--dotted-radio {
    margin-bottom: 20px;

    .ant-radio-inner {
        width: 14px;
        height: 14px;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: $green-spring;
    }

    .ant-radio-inner::after {
        background-color: $green-spring;
    }

    .dotted-radio-title {
        margin-bottom: 5px;
        font-weight: 500;
    }
}
