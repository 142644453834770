/*--------------------------------------------------------------
## Main Variables
--------------------------------------------------------------*/
// Fonts
$font-primary: 'Gilroy', 'sans-serif';
// $font-secondary: 'Zachar', 'serif';

$font-size: 14px;
$line-height: 20px;

// Colors
$white: #fff;
$black: #000;
$theme-blue: #548943;
$theme-green: #548943;
$green-spring: #8cc97b;
$green-medium: #e3f1dc;
$green-light: #f4f8f2;
$gray: #ced2d7;
$gray-medium: #848484;
$light-gray: #eff1f3;
$athens-gray: #fafbfc;
$yellow: #faad14;
$outher-space: #2f353b;
$red: #e11900;
$green: #17b794;
$shadow: rgba(0, 0, 0, 0.031);

$container: 1652px;
$container-large: 1885px;
$basePadding: 15px;
$dur: all 250ms;
$ease: ease-in-out;

/*--------------------------------------------------------------
## Grid Variables
--------------------------------------------------------------*/
$enable-grid-classes: true !default;

$grid-breakpoints: (
    xs: 416px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1253px
) !default;

/*
	Breakpoints for max-width.

	These variables are mapped with $grid-breakpoints SCSS map variable which is used for bootstrap.
	The reason for this setup is that it is needed to have variables that reflect bootstrap's breakpoints.
	Bootstrap is using min-with and this starter max-with approach, so bootstrap's breakpoint at 992px (min-width)
	is reflected as 991px (max-width), thus the mapped variables bellow.
 */

$xs: map-get($grid-breakpoints, xs) - 1;
$sm: map-get($grid-breakpoints, sm) - 1;
$md: map-get($grid-breakpoints, md) - 1;
$lg: map-get($grid-breakpoints, lg) - 1;
$xl: map-get($grid-breakpoints, xl) - 1;
$xxl: map-get($grid-breakpoints, xxl) - 1;

// Grid columns
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
