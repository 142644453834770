/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
footer {
    width: 100%;
    align-self: flex-end;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;

    a,
    span {
        font-size: 12px;
        letter-spacing: 0.2px;
        margin: 0 40px;
        color: $gray-medium;
        font-weight: 500;

        @include mq($md) {
            margin: 0 30px;
        }

        @include mq(640px) {
            margin: 0 5px;
            font-size: 11px;
        }

        @include mq(405px) {
            margin: 5px 0;
        }
    }

    a {
        text-decoration: underline;
        @include hover {
            color: $green-spring;
        }
    }

    @include mq(405px) {
        flex-direction: column-reverse;
    }
}

.footer__icon-help {
    display: flex;
    align-items: center;

    &:active {
        color: $gray-medium;
    }

    svg {
        fill: $gray-medium;
        transition: all 250ms ease-in-out;

        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }

    @include hover {
        svg {
            transition: all 250ms ease-in-out;
            fill: $green-spring;
        }
    }
}
