/*--------------------------------------------------------------
## Single Loan
--------------------------------------------------------------*/

.single-loan__summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 0 17px;
    border-bottom: 1px solid #e1e3e5;

    @include mq(1024px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @include mq(640px) {
        padding: 36px 0 17px;
    }
}

.single-loan__summary-header-title {
    font-size: 16px;
    color: $theme-green;
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    letter-spacing: -0.2px;
    margin: 0;

    svg {
        width: 39px;
        height: 27px;
        margin-right: 16px;
    }
}

.single-loan__summary-header-title-wrapp {
    display: flex;
    align-items: center;

    @include mq(640px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.single-loan__summary-pill {
    border-radius: 16px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    color: $theme-blue;
    font-family: $font-primary;
    font-weight: 600;
    background-color: $green-medium;
    margin-left: 41px;
    padding: 5px 20px;
    text-transform: none;

    @include mq(640px) {
        margin-left: 0;
        margin-top: 10px;
    }
}

.single-loan__summary-header-right-side-text {
    font-size: 12px;
    color: #8a94a6;
    margin-right: 29px;
    font-weight: 600;
    margin-top: 9px;

    /* @include mq(1024px) {
        margin-top: -4px;
    } */

    @include mq(410px) {
        margin-top: 10px;
        margin-bottom: 9px;
    }
}

.single-loan__summary-header-right-side-wrapper {
    display: flex;
    align-items: center;

    @include mq(640px) {
        width: 100%;
        margin-top: 15px;

        .loan-card__item.loan-card__item-status {
            margin-left: 0;
        }
    }

    /* @include mq(410px) {
        flex-direction: column;
        align-items: flex-start;

        .loan-card__item.loan-card__item-status {
            max-width: 100% !important;
        }
    } */
}

.single-loan__lists-tabs-wrapper {
    padding: 28px 35px 8px;

    @include mq(640px) {
        padding: 28px 0 8px;
    }
}

//Styles for Loan tabs
.single-loan__lists-wrapper {
    padding: 18px 0 0;
    position: relative;

    .add-loan-terms {
        position: absolute;
        top: 56px;
        right: 31px;
        z-index: 1;

        .add-loan-terms__dots {
            background-color: $green-medium;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 9px;
            width: 40px;
            height: 14px;
            padding: 5px 11px;
            cursor: pointer;
            transition: $dur $ease;

            @include hover {
                background-color: $theme-blue;
                transition: $dur $ease;

                .add-loan-term__dot {
                    background-color: $green-medium;
                    transition: $dur $ease;
                }
            }
        }

        .add-loan-term__dot {
            background-color: $theme-blue;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            transition: $dur $ease;
        }

        .button-wrapper {
            position: absolute;
            right: 0;
            top: 22px;
            opacity: 0;
            pointer-events: none;
            transition: $dur $ease;
            width: 177px;
            box-shadow: 1px 1px 6px #bbb;
            border-radius: 4px;

            &.add-loan-terms__button--show {
                opacity: 1;
                pointer-events: all;
                transition: $dur $ease;
            }
        }

        .add-loan-terms__button {
            border: none;
            background-color: $white;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 13px 22px 13px 12px;
            color: $outher-space;
            font-weight: 500;
            transition: $dur $ease;
            width: 100%;

            &:hover {
                background-color: $green-medium;
            }

            @include hover {
                color: $theme-blue;
                transition: $dur $ease;

                svg {
                    fill: $theme-blue;
                    transition: $dur $ease;
                }
            }
        }

        svg {
            width: 15px;
            height: 15px;
            fill: $outher-space;
            transition: $dur $ease;
            margin-right: 6px;
        }
    }

    .ant-tabs-tab {
        cursor: default;
    }
}

.single-loan__lists-summary {
    box-shadow: 0px 4px 12px #00000008;
    width: 100%;
    // max-height: 310px;
    border-radius: 4px;
    background-color: $white;
    padding: 28px 77px 45px 36px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 50px;
    margin-bottom: 18px;

    &:last-child {
        margin-bottom: 0;

        @include mq($xl) {
            margin-bottom: 18px;
        }
    }

    @include mq(1400px) {
        padding: 28px 37px 45px 36px;
    }

    @include mq($xl) {
        flex-direction: column;
    }

    @include mq(768px) {
        flex-direction: column;
        padding: 28px 15px 37px;
    }
}

.single-loan__lists-summary--full {
    .single-loan__lists-summary-title {
        max-width: 18.5%;
        flex: 0 0 18.5%;

        @include mq($xl) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .single-loan__lists-summary-items {
        max-width: 81.5%;
        flex: 0 0 81.5%;

        @include mq($xl) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .single-loan__lists-summary-items-left {
            max-width: 60%;
            flex: 0 0 60%;

            @include mq($xl) {
                max-width: 50%;
                flex: 0 0 50%;
            }

            @include mq(1024px) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .single-loan__lists-summary-items-right {
            margin-left: auto;
            max-width: 40%;
            flex: 0 0 40%;

            @include mq($xl) {
                max-width: 46%;
                flex: 0 0 46%;
            }

            @include mq(1024px) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }
}

.single-loan__lists-summary-wrapper {
    width: 49.4%;

    @include mq(1702px) {
        width: 49.2%;
    }

    @include mq(1280px) {
        width: 100%;
    }

    @include mq($xl) {
        width: 100%;
    }

    .single-loan__lists-summary-item-title {
        max-width: 40%;
        flex: 0 0 40%;
        width: 100%;

        @include mq(1500px) {
            max-width: 190px;
        }

        @include mq($xl) {
            max-width: 100%;
        }
    }

    .single-loan__lists-summary-items {
        max-width: 60%;
        flex: 0 0 60%;
        width: 100%;

        @include mq($xl) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.single-loan__lists-summary-wrapper--left {
    margin-right: 18px;
}

.single-loan__lists-summary--half {
    .single-loan__lists-summary-items-right {
        width: 100%;
        padding-left: 0;
    }

    .single-loan__lists-summary-item-title {
        max-width: 230px;

        @include mq(1500px) {
            max-width: 190px;
        }

        @include mq($xl) {
            max-width: 100%;
        }
    }

    .single-loan__lists-summary-item-val {
        @include mq(1500px) {
            max-width: 200px;
        }

        @include mq($xl) {
            max-width: 100%;
        }
    }

    .single-loan__lists-summary-item {
        @include mq($xl) {
            width: 100%;
            max-width: initial;
        }
    }
}

.single-loan__lists-summary-title {
    font-size: 24px;
    color: $black;
    font-weight: bold;
    text-transform: none;
    max-width: 316px;
    width: 100%;
    padding-right: 25px;

    @include mq(1730px) {
        max-width: 216px;
    }

    @include mq(1500px) {
        max-width: 180px;
    }

    @include mq($xl) {
        max-width: 100%;
        padding-right: 0;
    }
}

.single-loan__lists-summary-items {
    width: 100%;
    padding: 19px 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include mq(1024px) {
        flex-direction: column;
    }
}

.single-loan__lists-summary-items-left {
    width: 50%;

    @include mq(1024px) {
        width: 100%;
    }
}

.single-loan__lists-summary-items-right {
    width: 50%;
    padding-left: 6.5%;

    @include mq(1024px) {
        width: 100%;
        padding-left: 0;
    }
}

.single-loan__lists-summary-item {
    max-width: 100%;
    width: 100%;
    border-bottom: 1px solid #f5f7fa;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: 0 0 3px;
    margin-bottom: 7px;

    @include mq($xl) {
        max-width: 100%;
    }

    @include mq(768px) {
        max-width: 100%;
    }
}

.single-loan__lists-summary-item-title {
    font-size: 12px;
    color: #8a94a6;
    max-width: 162px;
    width: 100%;
    font-weight: 600;
    padding-right: 10px;

    @include mq($xl) {
        max-width: 45%;
    }

    @include mq(375px) {
        max-width: 130px;
    }
}

.single-loan__lists-summary-item-val {
    font-size: 14px;
    color: $outher-space;
    font-weight: 500;
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include mq($md) {
        max-width: 45%;
    }
}

.single-loan__summary-select-box {
    display: flex;
    align-items: center;
}

.single-loan__summary-select-arrow-box {
    width: 33px;
    background-color: #faad142e;
    margin-left: 2px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;

    svg {
        transform: rotate(-90deg);
        fill: #be7f00;
        width: 11px;
    }
}

.single-loan__lists-wrapper {
    .ant-tabs-tabpane {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq($xl) {
            flex-direction: column;
        }
    }
}

.ant-tooltip {
    max-width: 212px;
}

.ant-tooltip-inner {
    background-color: $white;
    box-shadow: 0 3px 20px $shadow;
    border-radius: 4px;
    color: $outher-space;
    font-weight: 600;
    padding: 9px 12px;
}

.ant-tooltip-arrow-content::before {
    background-color: $white;
    background: $white;
}

.lds-ring {
    /* change color here */
    color: $theme-blue;
}

.lds-ring,
.lds-ring div {
    box-sizing: border-box;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 0px;
    border: 2px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}