/*--------------------------------------------------------------
## Account created
--------------------------------------------------------------*/

.info-page__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 0 93px;

    @include mq(640px) {
        padding: 86px 0 158px;
    }

    @include mq(430px) {
        padding: 86px 0 112px;
    }

    @include mq(430px) {
        padding: 86px 0 50px;
    }
}

.info-page__wrapper--alt {
    padding: 85px 0 0;

    .page-info__title {
        margin-bottom: 48px;
        line-height: 1.2;
    }
}

.my-loans {
    .info-page__image {
        margin-bottom: -10px;
    }

    .page-info__title {
        transform: translateX(23px);

        @include mq(640px) {
            transform: translateX(13px);
        }
    }
}

.info-page__image {
    margin-bottom: 40px;

    img:not(.not-found) {
        width: 355px;
    }

    @include mq(640px) {
        max-width: 226px;
        width: 100%;
        margin-bottom: 5px;
        img {
            width: 100% !important;
        }
        &.reset-pass-success {
            width: auto;
        }
    }
}

.info-page__image-icon {
    height: 100%;
    width: 100%;
    svg {
        height: 100%;
        width: 100%;
    }
}

.page-info__title {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.6px;
    margin-bottom: 14px;
    text-align: center;

    @include mq(640px) {
        margin-bottom: 19px;
        font-size: 24px;

        br {
            display: none;
        }
    }
}

.page-info__subtitle {
    text-align: center;
    margin-bottom: 40px;
    color: $gray-medium;
    font-weight: 500;
    font-size: 16px;
}

.page-info__subtitle--span span {
    display: inline-block;
    margin-left: 5px;
}
