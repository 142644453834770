/*--------------------------------------------------------------
## Buttons
--------------------------------------------------------------*/

.btn,
.ant-btn {
    width: 100%;
    min-width: 190px;
    height: 44px;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: $white;
    font-size: 14px;
    letter-spacing: -0.1px;
    border: none;
    transition: $dur $ease;
    text-align: center;
    font-weight: 500;

    @include mq(411px) {
        font-size: 13px;
    }

    span {
        display: flex;
        color: $white;
        font-size: 15px;
        letter-spacing: -0.1px;
        transition: $dur $ease;
    }

    @include hover {
        color: $black;
        background-color: $green-spring;

        span,
        svg {
            color: $black;
            fill: $black;
        }
        path {
            stroke: $black;
        }
        .btn__icon {
            transform: translateX(4px);
        }

        .plus {
            transform: translateX(0);
        }
    }

    &:disabled {
        // pointer-events: none;
        background-color: $gray !important;
        color: #848484;
        // opacity: 0.5;
        cursor: no-drop;
        pointer-events: none;

        span {
            color: #848484;

            svg {
                fill: #848484;
            }
        }

        @include hover {
            background-color: $gray;
        }
    }

    .plus {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 14px;
            margin-left: -8px;
            margin-top: -1px;
            border-top: 2px solid;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            height: 13px;
            margin-left: -2px;
            margin-top: -7px;
            border-left: 2px solid;
        }
    }
}

.icon-left {
    .btn__icon {
        margin-right: 16px;
    }
}

.btn--small {
    min-width: 133px;

    @include mq(411px) {
        min-width: 100px;
    }
}

.blue {
    --background-color: #548943;

    @include hover {
        --background-color-hover: $green-spring;
    }
}

.white {
    background-color: #fff;
    border: 2px solid $theme-green !important;
    color: $theme-green;
    @include hover {
        background-color: $theme-green;
        color: $white;
        span {
            color: $white;
        }
    }

    &.ant-btn {
        span {
            color: $theme-green;
            font-weight: 600;
            letter-spacing: normal;
            font-size: 14px;
        }

        @include hover {
            background-color: $theme-green;
            color: $white;

            span {
                color: $white;
            }
        }
    }
}

.btn__icon {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 13px;
    margin-left: 10px;
    transition: $dur $ease;
    svg {
        width: 100%;
        fill: $white;
        height: 100%;
        display: flex;
        transition: $dur $ease;
    }
}

.ant-btn:focus,
.ant-btn:active {
    background-color: var(--background-color);
}

.inline-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
    button {
        min-width: 0;
    }
}

.btn--back {
    margin-right: 15px;
    background-color: #f5f7fa;
    span {
        color: #548943;
    }
    svg {
        fill: #548943;
    }
    .btn__icon {
        transform: rotate(180deg);
        margin-left: 0;
        margin-right: 10px;
    }
    @include hover {
        .btn__icon {
            transform: rotate(180deg) translateX(4px);
        }
    }
}
