/*--------------------------------------------------------------
## Reset password
--------------------------------------------------------------*/

.reset-password {
    padding: 144px 0 190px;

    @include mq(1440px) {
        padding: 100px 0 85px;
    }

    @include mq(640px) {
        padding: 44px 0 10px;
    }
}

.reset-password__title {
    text-align: center;
    margin-bottom: 102px;

    @include mq(640px) {
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: -0.6px;
        margin-bottom: 18px;
    }
}

.reset-password__box {
    max-width: 454px;
    width: 100%;
    background-color: $white;
    box-shadow: 0px 50px 99px #01084c0d;
    border: 1px solid #e7e9f2;
    border-radius: 4px;
    padding: 42px 39px 36px;
    margin: 0 auto;

    .form-element {
        margin-bottom: 14px;
    }

    @include mq(640px) {
        padding: 30px 41px 35px;
    }

    @include mq(411px) {
        padding: 30px 15px 35px;
    }
}

.create-account__req--reset-pass {
    margin-bottom: 15px;
    margin-top: 25px;
}

.reset-password__btn {
    margin-top: 42px;
}

input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
}
